<template>
  <div>
    <v-btn color="primary" class="mt-4" @click="visible = true"
      >Nueva oportunidad</v-btn
    >
    <SetOpportunity
      :visible="visible"
      :opportunity="opportunity"
      :opportunityContacts="opportunityContacts"
      :opportunitySolutions="opportunitySolutions"
      :opportunitySolutionsTypes="opportunitySolutionsTypes"
      :opportunityCrossBu="opportunityCrossBu"
      :opportunityCrossSeller="opportunityCrossSeller"
      @closeDialog="closeDialog"
      :key="'op' + key"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import axios from 'axios'
import SetOpportunity from '@/components/SetOpportunity.vue'

export default {
  components: {
    SetOpportunity
  },
  data() {
    return {
      opportunity: {},
      opportunityContacts: [],
      opportunitySolutions: [],
      opportunitySolutionsTypes: [],
      opportunityCrossBu: [],
      opportunityCrossSeller: [],
      key: 0,
      visible: true
    }
  },
  methods: {
    closeDialog() {
      this.visible = false
      this.key++
    }
  },
  computed: {
    ...mapState('account', ['darkTheme'])
  }
}
</script>
